<template>
  <div ref="outer" @click.stop>
    <slot></slot>
    <Teleport to="body">
      <div
        v-show="show" class="azgo-search-tips" p="6" pb="10" bg="#fff" rounded="4" shadow="[0_8px_24px_0_#00000014]" position="fixed" right="0" z="1999"
        :style="{
          width: `${shadowRect.width}px`,
          left: `${shadowRect.left}px`,
          top: `${shadowRect.top}px`,
        }"
        @click.stop
      >
        <template v-if="citySearchHistory.length">
          <div text="lg #121212" font="500" mb="3">
            {{ t('key.search.destination.history') }}
          </div>
          <div mb="3">
            <van-radio-group direction="horizontal">
              <BaseAZRadioButton
                v-for="item in citySearchHistory"
                :key="typeof item === 'string' ? item : item.cityId"
                mb="2"
                :label="typeof item === 'string' ? item : item.cityName"
                :name="typeof item === 'string' ? item : item.cityId"
                @click="handleSelectCity(item)"
              >
              </BaseAZRadioButton>
            </van-radio-group>
          </div>
        </template>

        <div text="lg #121212" font="500" mb="3">
          {{ t('key.search.destination.featured') }}
        </div>
        <div>
          <van-radio-group direction="horizontal">
            <BaseAZRadioButton
              v-for="item in popularCityList"
              :key="item.cityId" mb="2"
              :label="item.cityName"
              :name="item.cityId"
              @click="handleSelectCity(item)"
            >
            </BaseAZRadioButton>
          </van-radio-group>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script lang="ts" setup>
import { api } from '~/apis/back/services'
import type { RegionSearchResponse } from '~/apis/back/types'

const show = defineModel('show', {
  type: Boolean,
  default: false,
})

const outerRef = useTemplateRef('outer')

const shadowRect = ref({
  width: 0,
  top: 0,
  left: 0,
})

const { scrollContainer } = useScrollContainer()
const { y } = useScroll(scrollContainer)

useBlankClick(() => {
  show.value = false
})

const { t } = useI18n()
const {
  citySearchHistory,
  clickHistory,
} = useSearchResult()
const { data: popularData } = api.regionPopularPost({})

const popularCityList = computed(() => {
  return popularData.value?.data || []
})

watch([() => y.value, () => show.value], () => {
  if (show.value) {
    calculateShadowRect()
  }
})

onMounted(() => {
  useResizeObserver(document.body, () => {
    if (show.value) {
      calculateShadowRect()
    }
  })
})

function calculateShadowRect() {
  const rect = outerRef.value?.getBoundingClientRect()
  shadowRect.value = {
    width: rect?.width ?? 0,
    top: (rect?.bottom ?? 0) + 20,
    left: (rect?.left ?? 0),
  }
}

function handleSelectCity(item: RegionSearchResponse | string) {
  show.value = false
  return clickHistory(item)
}
</script>

<style>

</style>
