<template>
  <div class="relative mx-auto max-w-300 transition-all" h="20" flex="~ items-center justify-between">
    <div flex="~ items-center" font="300" text="xs white">
      <template v-if="isPartner">
        <NuxtLink :to="cobrand.logoLink || { path: `/${locale}`, query: getCommonQuery() }" :external="!!cobrand.logoLink" target="_blank" rel="noopener noreferrer">
          <img v-if="cobrand.logo" :src="cobrand.logo?.replace(/^http:\/\//i, 'https://')" class="max-w-25 object-contain align-middle" h="6" />
          <span v-else-if="cobrand.name" class="text-white md:ml-2 md:text-lg">{{ cobrand.name }}</span>
        </NuxtLink>
        <template v-if="commonDataStore.commonData.partnerInfo?.showPoweredText">
          <span mx="1.5 md:4" w="1px" :bg="theme === 'white' ? 'white' : '#121212'" h="4 md:6"></span>
          <span class="md:text-sm" mr="1 md:2" :text="theme === 'white' ? 'white' : '#121212'">{{ t('key.business.user.header.poweredBy') }}</span>
        </template>
      </template>
      <NuxtLink :to="{ path: `/${locale}`, query: getCommonQuery() }">
        <img v-if="!isPartner" w="20 md:30" self="end md:center" class="mt--1" :src="theme === 'white' ? logoWhite : logoGreen" />
        <img v-else-if="commonDataStore.commonData.partnerInfo?.showPoweredText" w="14 md:20" self="end md:center" :src="theme === 'white' ? logoWhite : logoGreen" />
      </NuxtLink>
    </div>

    <!-- 搜索框 -->
    <DesktopSearchDrawer v-if="showSearch" v-model:show="searchShow" ml="20" mr="6" flex="1">
      <div class="relative" b="2 solid #00000014" bg="white" pl="6" pr="1" h="14" flex="1 ~ items-center" rounded="15" py="1">
        <NuxtIcon name="attr-icon:search" class="mr-2" text="xl" />
        <input v-model="inputValue" flex="1" text="base" :placeholder="t('key.search.result.input.search.placeholder')" class="placeholder:text-#B2B2B2 placeholder:font-300" type="search" pr="2" @search="handleSearch()" @focus="showTips" />
        <div v-if="inputValue" w="8" h="8" flex="~ items-center justify-center" mr="3" rounded="full" bg="#F5F5F5" class="azgo-haptics-feedback" @click="clearSearch">
          <NuxtIcon name="my-icon:close" class="#121212 azgo-hover-feedback text-3" />
        </div>
        <div cursor="pointer" hover="opacity-80" class="azgo-haptics-feedback" :style="{ background: 'var(--tc-color-primary)' }" px="8" h="full" rounded="15" flex="~ items-center justify-center" text="white base" font="500" @click="handleSearch()">
          {{ t('key.attractions.search.todo') }}
        </div>
      </div>
    </DesktopSearchDrawer>

    <div flex="~ items-center" space-x="2">
      <div class="azgo-haptics-feedback hover:bg-#0000000A" cursor="pointer" rounded="10" flex="~ items-center" px="4" py="2" space-x="2" :class="[theme === 'white' && 'text-white']" @click="showCurrencyPopup = true">
        <NuxtIcon name="attr-icon:currency" class="text-2xl" />
        <span text="xs">{{ currentCurrency }}</span>
      </div>
      <div class="azgo-haptics-feedback hover:bg-#0000000A" cursor="pointer" rounded="10" flex="~ items-center" px="4" py="2" space-x="2" :class="[theme === 'white' && 'text-white']" @click="showLocalePopup = true">
        <NuxtIcon name="attr-icon:language" class="text-2xl" />
        <span text="xs"> {{ langPCMap[locale as string] }}</span>
      </div>
    </div>

    <!-- 选择货币弹框 -->
    <BaseAZDialog v-model:show="showCurrencyPopup" :title="t('key.business.user.header.selectCurrency')">
      <div grid="~ cols-3 gap-4">
        <div
          v-for="item in currencyInfo"
          :key="item.currency"
          class="hover:bg-#0000000A"
          cursor="pointer"
          :style="item.currency === currentCurrency && 'border-color: var(--tc-color-primary); color: var(--tc-color-primary); background-color: var(--el-color-primary-light-9);'" h="12" font="300" flex="~ items-center justify-center" b="1 solid #00000014" bg="white" rounded="2" text="#121212" @click="currentCurrency = item.currency || ''"
        >
          <span text="sm" font="500" mr="2">{{ item.currency }}</span>
          <span text="sm" font="300">{{ item.fullName }}</span>
        </div>
      </div>
    </BaseAZDialog>

    <!-- 选择语言弹框 -->
    <BaseAZDialog v-model:show="showLocalePopup" :title="t('key.lang')">
      <div grid="~ cols-4 gap-x-4">
        <div
          v-for="(item) in locales || []"
          :key="item.locale"
          class="hover:bg-#0000000A"
          cursor="pointer"
          :style="currentLocale === item && 'border-color: var(--tc-color-primary); color: var(--tc-color-primary); background-color: var(--el-color-primary-light-9);'" h="12" font="300" flex="~ items-center justify-center" b="1 solid #00000014" bg="white" rounded="2" text="#121212"
          @click="currentLocale = item;"
        >
          <span text="sm" font="300">{{ langPCMap[item as string] }}</span>
        </div>
      </div>
    </BaseAZDialog>
  </div>
</template>

<script lang="ts" setup>
import logoWhite from '@/assets/icons/azgoxplore.png'
import logoGreen from '@/assets/icons/azgoxplore-green.png'
import { getCommonQuery } from '~/utils'
import { langPCMap } from '~/constant/languages'

const { theme } = defineProps({
  theme: {
    type: String as PropType<'green' | 'white'>,
    default: 'green',
  },
  showSearch: {
    type: Boolean,
    default: true,
  },
})

const { t } = useI18n()
const { locale } = useLocale()
const { searchHandler } = useSearchResult()
const commonDataStore = useCommonStore()
const route = useRoute()

const searchShow = ref(false)

function showTips() {
  searchShow.value = true
}

const { showCurrencyPopup, currencyInfo, currentCurrency, showLocalePopup, locales, currentLocale } = useNavbar()

const inputValue = ref(route.query.keyword as string || '')

watch(() => route.query.keyword, (newVal) => {
  inputValue.value = newVal as string || ''
})

const cobrand = computed(() => {
  const { partnerInfo } = commonDataStore.commonData || {}

  return {
    logo: partnerInfo?.distLogo,
    name: partnerInfo?.distName,
    logoLink: partnerInfo?.distLogoSkipUrl,
  }
})

const isPartner = computed(() => Boolean(route.query.campaign_id) && (cobrand.value.logo || cobrand.value.name))

function clearSearch() {
  inputValue.value = ''
  handleSearch()
}

function handleSearch() {
  searchShow.value = false
  searchHandler(inputValue.value, true)
}
</script>

<style>

</style>
